<template>
  <div class="row">
    <BulkAction
      v-if="hasBulkAction"
      :bulkLoading="bulkLoading"
      :hasMultiSelect="hasMultiSelect"
      :exportLoading="exportLoading"
      :permissions="permissions"
      :refs="refs"
      :search="search"
      :period="period"
      :time="time"
      :date="date"
      :paid="paid"
      :status="status"
      :paginate="perPage"
      :lecturer_id="lecturer_id"
      v-on:activeChange="handelActiveChange"
      v-on:inactiveChange="handelInActiveChange"
      v-on:refreshTable="fetchData"
      :fields="fields"
      :selected="selected"
    >
    </BulkAction>

    <div class="col-12">
      <div
        v-if="hasReportDropDown"
        class="dropdown"
        style="z-index: 99; margin-top: -15px; margin-bottom: 10px"
      >
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
        >
          {{
            currentReportType
              ? $t("nav." + currentReportType)
              : $t("app.choose_report")
          }}
        </button>
        <div
          class="dropdown-menu"
          style="overflow-y: auto"
          aria-labelledby="dropdownMenuButton"
        >
          <router-link
            v-for="(type, idx) in reportTypes"
            :key="idx"
            class="dropdown-item"
            :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
            :to="{ name: 'show-reports', params: { type: type } }"
          >
            {{ $t("nav." + type) }}
          </router-link>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-sm-11"></div>
            <div class="col-sm-1" style="height: 40px">
              <ShowEntries
                :perPage="this.perPage"
                v-on:showChange="handelShowChange"
              >
              </ShowEntries>
            </div>
            <div
              class="col-sm-12 mtop-20"
              :style="$i18n.locale == 'ar' ? '' : 'text-align: left'"
            >
              <h5 :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'mrl-5'">
                {{ $t("app.all_rows") }}
                <span v-if="dataLoading">
                  <span
                    class="spinner-grow spinner-grow-sm mr-1"
                    role="status"
                    aria-hidden="true"
                  >
                  </span>
                </span>
                <span v-if="!dataLoading">( {{ total }} )</span>
              </h5>

              <div
                v-if="hasSearchFilter"
                style="min-width: 20%"
                class="search-box me-2 mb-2 d-inline-block"
              >
                <div class="position-relative">
                  <form @submit.prevent="fetchData">
                    <input
                      type="text"
                      class="form-control"
                      :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                      :placeholder="lblSearch"
                      v-model="search"
                    />
                    <i class="bx bx-search-alt search-icon"></i>
                  </form>
                </div>
              </div>

              <div
                v-if="
                  hasLecturerFilter && !lecturerLoading && auth.role == 'root'
                "
                class="search-box ml-2 d-inline-block"
              >
                <div class="position-relative">
                  <select
                    class="form-control"
                    v-model="lecturer_id"
                    @change="fetchCat"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    <option value="">{{ $t("view.lecturer_name") }}</option>
                    <option
                      v-for="(lecturer, idx) in lecturers"
                      :key="idx"
                      :value="lecturer.id"
                    >
                      {{ lecturer[$i18n.locale]["title"] }}
                    </option>
                  </select>
                </div>
              </div>

              <div
                v-if="hasCategoryFilter && !categoryLoading && lecturer_id"
                class="search-box ml-3 d-inline-block"
              >
                <div class="position-relative">
                  <select
                    class="form-control"
                    v-model="category_id"
                    @change="fetchData"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    <option value="">{{ $t("view.category_title") }}</option>
                    <option
                      v-for="(cat, idx) in categories"
                      :key="idx"
                      :value="cat.id"
                    >
                      {{ cat[$i18n.locale]["title"] }}
                    </option>
                  </select>
                </div>
              </div>

              <div
                v-if="hasRoleFilter && !roleLoading"
                class="search-box ml-3 d-inline-block"
              >
                <div class="position-relative">
                  <select
                    class="form-control"
                    v-model="role"
                    @change="fetchData"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-15' : ''
                    "
                  >
                    <option value="">{{ $t("view.role_name") }}</option>
                    <option
                      v-for="(role, idx) in roles"
                      :key="idx"
                      :value="role.name"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div
                v-if="hasPeriodFilter"
                class="search-box d-inline-block"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
              >
                <div class="position-relative">
                  <select
                    class="form-control"
                    v-model="period"
                    @change="fetchData"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-15'
                        : 'pd-10'
                    "
                  >
                    <option value="">{{ $t("app.period") }}</option>
                    <option value="15">15 {{ $t("app.minutes") }}</option>
                    <option value="30">30 {{ $t("app.minutes") }}</option>
                    <option value="60">60 {{ $t("app.minutes") }}</option>
                    <option value="1">1 {{ $t("app.hours") }}</option>
                    <option value="2">2 {{ $t("app.hours") }}</option>
                    <option value="3">3 {{ $t("app.hours") }}</option>
                    <option value="4">4 {{ $t("app.hours") }}</option>
                    <option value="5">5 {{ $t("app.hours") }}</option>
                    <option value="6">6 {{ $t("app.hours") }}</option>
                    <option value="7">7 {{ $t("app.hours") }}</option>
                    <option value="8">8 {{ $t("app.hours") }}</option>
                  </select>
                </div>
              </div>

              <div
                v-if="hasTimeFilter"
                class="search-box d-inline-block"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
              >
                <div class="position-relative">
                  <input
                    type="time"
                    class="form-control"
                    v-model="time"
                    @change="fetchData"
                  />
                  <span class="search-icon">
                    <i
                      v-if="time"
                      @click="clearTime"
                      class="fa fa-times cursor-pointer font-size-13"
                    ></i>
                  </span>
                </div>
              </div>

              <div
                v-if="hasTimeFromFilter"
                class="search-box d-inline-block"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
              >
                <div class="position-relative">
                  <input
                    type="time"
                    class="form-control"
                    v-model="time_from"
                    @change="fetchData"
                  />
                  <span class="search-icon">
                    <i
                      v-if="time_from"
                      @click="clearTimeFrom"
                      class="fa fa-times cursor-pointer font-size-13"
                    ></i>
                  </span>
                </div>
              </div>

              <div
                v-if="hasTimeToFilter"
                class="search-box d-inline-block"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
              >
                <div class="position-relative">
                  <input
                    type="time"
                    class="form-control"
                    v-model="time_to"
                    @change="fetchData"
                  />
                  <span class="search-icon">
                    <i
                      v-if="time_to"
                      @click="clearTimeTo"
                      class="fa fa-times cursor-pointer font-size-13"
                    ></i>
                  </span>
                </div>
              </div>

              <div
                v-if="hasDateFilter"
                class="search-box d-inline-block"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
              >
                <div class="position-relative">
                  <input
                    type="date"
                    class="form-control"
                    v-model="date"
                    @change="fetchData"
                  />
                  <span class="search-icon">
                    <i
                      v-if="date"
                      @click="clearDate"
                      class="fa fa-times cursor-pointer font-size-13"
                    ></i>
                  </span>
                </div>
              </div>

              <div
                v-if="hasDateFromFilter"
                class="search-box d-inline-block"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
              >
                <div class="position-relative">
                  <input
                    type="date"
                    class="form-control"
                    v-model="date_from"
                    @change="fetchData"
                  />
                  <span class="search-icon">
                    <i
                      v-if="date_from"
                      @click="clearDateFrom"
                      class="fa fa-times cursor-pointer font-size-13"
                    ></i>
                  </span>
                </div>
              </div>

              <div
                v-if="hasDateToFilter"
                class="search-box d-inline-block"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
              >
                <div class="position-relative">
                  <input
                    type="date"
                    class="form-control"
                    v-model="date_to"
                    @change="fetchData"
                  />
                  <span class="search-icon">
                    <i
                      v-if="date_to"
                      @click="clearDateTo"
                      class="fa fa-times cursor-pointer font-size-13"
                    ></i>
                  </span>
                </div>
              </div>

              <div
                v-if="hasPaidFilter"
                class="search-box d-inline-block"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
              >
                <div class="position-relative">
                  <select
                    class="form-control"
                    v-model="paid"
                    @change="fetchData"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-15'
                        : 'pd-10'
                    "
                  >
                    <option value="">{{ $t("app.paid") }}</option>
                    <option
                      value="success"
                      v-html="$t('app.payment_success')"
                    ></option>
                    <option
                      value="fail"
                      v-html="$t('app.payment_not_success')"
                    ></option>
                  </select>
                </div>
              </div>

              <div
                v-if="hasStatusFilter"
                class="search-box d-inline-block"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
              >
                <div class="position-relative">
                  <select
                    class="form-control"
                    v-if="hasStatusApproved"
                    v-model="status"
                    @change="fetchData"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-15'
                        : 'pd-10'
                    "
                  >
                    <option value="">{{ $t("app.status") }}</option>
                    <option value="new" v-html="$t('app.new')"></option>
                    <option
                      value="approved"
                      v-html="$t('app.approved')"
                    ></option>
                    <option
                      value="unapproved"
                      v-html="$t('app.unapproved')"
                    ></option>
                    <option
                      value="delayed"
                      v-html="$t('app.has_delayed')"
                    ></option>
                  </select>
                  <select
                    class="form-control"
                    v-else
                    v-model="status"
                    @change="fetchData"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-15'
                        : 'pd-10'
                    "
                  >
                    <option value="">{{ $t("app.status") }}</option>
                    <option
                      value="active"
                      v-html="hasSeen ? $t('app.seen') : $t('app.active')"
                    ></option>
                    <option
                      value="inactive"
                      v-html="hasSeen ? $t('app.unseen') : $t('app.inactive')"
                    ></option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table
              id="printMe"
              class="table align-middle table-nowrap table-check"
            >
              <thead class="table-dark">
                <tr>
                  <th
                    v-if="hasMultiSelect"
                    style="width: 5%"
                    class="align-middle"
                  >
                    <div class="form-check font-size-16">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="checkAll"
                        v-model="selectAll"
                        @click="select"
                      />
                      <label class="form-check-label" for="checkAll"></label>
                    </div>
                  </th>

                  <th v-if="hasHashId" style="width: 5%" class="text-center">
                    #
                  </th>

                  <th
                    v-if="hasImage"
                    style="width: 5%"
                    class="align-middle font-weight-500 text-center"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ image }}
                  </th>

                  <th
                    v-if="hasUser"
                    style="width: 5%"
                    class="align-middle font-weight-500 text-center"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ image }}
                  </th>

                  <th
                    v-if="hasTitle"
                    style="width: 10%"
                    class="align-middle font-weight-500"
                    :class="[
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16'
                        : 'font-size-14 text-center',
                      hasImage || hasUser ? 'text-center' : '',
                    ]"
                  >
                    {{ title }}
                  </th>

                  <th
                    v-if="hasName"
                    style="width: 10%"
                    class="align-middle font-weight-500"
                    :class="[
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16'
                        : 'font-size-14 text-center',
                      hasImage ? 'text-center' : '',
                    ]"
                  >
                    {{ name }}
                  </th>

                  <th
                    v-if="hasModule"
                    style="width: 10%"
                    class="align-middle font-weight-500 text-center"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ module }}
                  </th>

                  <th
                    v-if="hasDetail"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ detail }}
                  </th>

                  <th
                    v-if="hasCoupons"
                    style="width: 10%"
                    class="align-middle font-weight-500 text-center"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ coupons }}
                  </th>

                  <th
                    v-if="hasPosition"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ position }}
                  </th>

                  <th
                    v-if="hasPeriod"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.period") }}
                  </th>

                  <th
                    v-if="hasQuestion"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.question") }}
                  </th>

                  <th
                    v-if="hasCategory"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ category }}
                  </th>

                  <th
                    v-if="hasPrice"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ price }}
                  </th>

                  <th
                    v-if="hasPercentage"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ percentage }}
                  </th>

                  <th
                    v-if="hasAttendance"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ attendance }}
                  </th>

                  <th
                    v-if="hasQty"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ qty }}
                  </th>

                  <th
                    v-if="hasBalance"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria  font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.balance") }}
                  </th>

                  <th
                    v-if="hasEmail"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria  font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ email }}
                  </th>

                  <th
                    v-if="hasSubject"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria  font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ subject }}
                  </th>

                  <th
                    v-if="hasCountryCode"
                    style="width: 5%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria  font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ countryCode }}
                  </th>

                  <th
                    v-if="hasMobile"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria  font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ mobile }}
                  </th>

                  <th
                    v-if="hasRole"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria  font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ roleLabel }}
                  </th>

                  <th
                    v-if="hasTimeFrom"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.time_from") }}
                  </th>

                  <th
                    v-if="hasTimeTo"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.time_to") }}
                  </th>

                  <th
                    v-if="hasTime"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.time") }}
                  </th>

                  <th
                    v-if="hasPublishedDate"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("view.published_at") }}
                  </th>

                  <th
                    v-if="hasDate"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.date") }}
                  </th>

                  <th
                    v-if="hasDateFrom"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.start_date") }}
                  </th>

                  <th
                    v-if="hasDateTo"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.end_date") }}
                  </th>

                  <th
                    v-if="hasPaid"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.paid") }}
                  </th>

                  <th
                    v-if="hasStatus || hasStatus2"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16 '
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.status") }}
                  </th>

                  <th
                    v-if="
                      (hasProgress &&
                        currentReportType == 'offlineCoursesAttendance') ||
                      currentReportType == 'educationalProgram'
                    "
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.progress") }}
                  </th>
                  <th
                    v-if="hasActions"
                    style="width: 10%"
                    class="align-middle text-center font-weight-500"
                    :class="
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-16'
                        : 'font-size-14'
                    "
                  >
                    {{ $t("app.actions") }}
                  </th>
                </tr>
              </thead>

              <tbody v-if="dataLoading">
                <tr>
                  <td :colspan="colspan" class="text-center">
                    <span
                      class="spinner-grow spinner-grow-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    >
                    </span>
                  </td>
                </tr>
              </tbody>

              <tbody v-if="!dataLoading && !items.length">
                <tr>
                  <td
                    :colspan="colspan"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <span>{{ $t("app.no_data") }}</span>
                  </td>
                </tr>
              </tbody>

              <tbody v-if="!dataLoading && items.length">
                <tr
                  v-for="(item, idx) in items"
                  :key="idx"
                  :class="item.is_over ? 'is-over-tr' : ''"
                >
                  <td v-if="hasMultiSelect">
                    <div class="form-check font-size-16">
                      <input
                        :id="'expBox' + item.id"
                        class="form-check-input"
                        type="checkbox"
                        v-model="selected"
                        :value="item.id"
                      />
                      <label class="form-check-label" :for="'expBox' + item.id">
                      </label>
                    </div>
                  </td>

                  <td
                    v-if="hasHashId"
                    class="text-center"
                    style="vertical-align: middle"
                  >
                    {{ idx + 1 }}
                  </td>

                  <td class="text-center" v-if="hasImage">
                    <router-link
                      v-if="permissions.viewRow"
                      :to="{
                        name: 'edit-' + refs,
                        params: { id: item.encrypt_id },
                      }"
                    >
                      <img
                        :src="item.image ? item.image.url : ''"
                        :style="image_style"
                      />
                    </router-link>

                    <img
                      v-if="!permissions.viewRow"
                      :src="item.image ? item.image.url : ''"
                      :style="image_style"
                    />
                  </td>

                  <td
                    class="text-center"
                    style="vertical-align: middle"
                    v-if="hasUser"
                  >
                    <router-link
                      v-if="permissions.viewRow"
                      :to="{
                        name: 'edit-users',
                        params: { id: item.user.encrypt_id },
                      }"
                    >
                      <img
                        v-if="permissions.edit && item.user"
                        :src="item.user ? item.user.url : ''"
                        :style="image_style"
                        :title="item.user ? item.user.name : ''"
                        style="cursor: pointer"
                      />
                    </router-link>
                  </td>

                  <td
                    v-if="hasTitle"
                    style="vertical-align: middle"
                    :class="[
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-14'
                        : 'text-center',
                      hasImage || hasUser ? 'text-center' : '',
                    ]"
                  >
                    <router-link
                      v-if="permissions.viewRow"
                      :to="{
                        name: 'edit-' + refs,
                        params: { id: item.encrypt_id },
                        query: { parent_id: parent_id, slug: slug },
                      }"
                      class="default-color"
                    >
                      {{ $i18n.locale == "ar" ? item.ar.title : item.en.title }}
                    </router-link>

                    <span v-if="!permissions.edit">
                      {{ $i18n.locale == "ar" ? item.ar.title : item.en.title }}
                    </span>
                  </td>

                  <td
                    v-if="hasName"
                    style="vertical-align: middle"
                    :class="[
                      $i18n.locale == 'ar'
                        ? 'hacen_algeria font-size-14'
                        : 'text-center',
                      hasImage ? 'text-center' : '',
                    ]"
                  >
                    <router-link
                      v-if="permissions.viewRow"
                      :to="{
                        name: 'edit-' + refs,
                        params: { id: item.encrypt_id },
                        query: { parent_id: parent_id, slug: slug },
                      }"
                      class="default-color"
                    >
                      {{ item.name }}
                    </router-link>

                    <span v-if="!permissions.viewRow">
                      {{ item.name }}
                    </span>
                  </td>

                  <td
                    v-if="hasModule"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-success text-center"
                      style="padding: 10px; font-size: 13px"
                    >
                      {{ $t("nav." + item.model_type) }}
                    </label>
                    <br />
                    <label
                      v-if="item.item_name"
                      class="badge rounded-pill badge-md badge-soft-success text-center"
                      style="padding: 10px; font-size: 13px"
                    >
                      {{ item.item_name }}
                    </label>
                  </td>

                  <td
                    v-if="hasDetail"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-danger text-center"
                      style="padding: 10px; font-size: 13px"
                    >
                      {{ item.detail }}
                    </label>
                  </td>

                  <td
                    v-if="hasCoupons"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-primary text-center"
                      style="padding: 10px; font-size: 13px"
                    >
                      {{ item.used }} / {{ item.available }}
                    </label>
                  </td>

                  <td
                    v-if="hasPosition"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-primary"
                      style="padding: 10px; font-size: 13px"
                    >
                      {{ item.position }}
                    </label>
                  </td>

                  <td
                    v-if="hasPeriod"
                    class="text-center"
                    :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                    style="vertical-align: middle"
                  >
                    <label
                      class="badge rounded-pill badge-lg"
                      style="padding: 8px"
                      :class="[
                        $i18n.locale == 'ar' ? 'font-size-14' : 'font-size-13',
                        item.period == 15
                          ? 'badge-soft-info'
                          : item.period == 30
                          ? 'badge-soft-primary'
                          : 'badge-soft-danger',
                      ]"
                    >
                      {{ item.period }}
                      {{
                        item.period == "15" ||
                        item.period == "30" ||
                        item.period == "60"
                          ? $t("app.minutes")
                          : $t("app.hours")
                      }}
                    </label>
                  </td>

                  <td
                    v-if="hasQuestion"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <router-link
                      :to="{
                        name: 'show-' + $props.refs,
                        params: { id: item.encrypt_id },
                      }"
                      class="badge rounded-pill badge-md badge-soft-secondary"
                      style="padding: 10px; font-size: 13px"
                    >
                      {{ item.tiny_question }}
                    </router-link>
                  </td>

                  <td
                    v-if="hasCategory"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-danger"
                      style="padding: 10px"
                      :class="
                        $i18n.locale == 'ar' ? 'font-size-13' : 'font-size-12'
                      "
                    >
                      {{ item.category_name }}
                    </label>
                  </td>

                  <td
                    v-if="hasPrice"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-secondary"
                      style="padding: 10px"
                      :class="
                        $i18n.locale == 'ar' ? 'font-size-13' : 'font-size-12'
                      "
                    >
                      {{ item.price }} {{ $t("app.kw") }}
                    </label>
                    <!-- <i v-if="item.discount"
                                            class="mdi mdi-flash font-size-16"
                                            :title="item.discount+' '+$t('app.kw')">
                                        </i> -->
                  </td>

                  <td
                    v-if="hasPercentage"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-secondary"
                      style="padding: 10px"
                      :class="
                        $i18n.locale == 'ar' ? 'font-size-13' : 'font-size-12'
                      "
                    >
                      {{ item.percentage }} %
                    </label>
                  </td>

                  <td
                    v-if="hasQty"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-danger"
                      style="padding: 10px; font-size: 13px"
                    >
                      {{ item.qty }}
                    </label>
                  </td>

                  <td
                    v-if="hasAttendance"
                    class="text-center"
                    style="vertical-align: middle"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-danger"
                      style="padding: 10px; font-size: 13px"
                    >
                      {{ item.attendances_no }}
                    </label>
                  </td>

                  <td
                    v-if="hasBalance"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-danger"
                      style="padding: 10px; font-size: 13px"
                    >
                      {{ item.balance }} {{ $t("app.kw") }}
                    </label>
                  </td>

                  <td
                    v-if="hasEmail"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    {{ item.email }}
                  </td>

                  <td
                    v-if="hasSubject"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    {{ item.subject }}
                  </td>

                  <td
                    v-if="hasCountryCode"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    {{ item.country_code }}
                  </td>

                  <td
                    v-if="hasMobile"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    {{ item.mobile }}
                  </td>

                  <td
                    v-if="hasRole"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    <label
                      class="badge rounded-pill badge-md badge-soft-danger"
                      style="padding: 10px; font-size: 13px"
                    >
                      {{ item.role_name }}
                    </label>
                  </td>

                  <td
                    v-if="hasTimeFrom"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    {{ item.time_from }}
                  </td>

                  <td
                    v-if="hasTimeTo"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    {{ item.time_to }}
                  </td>

                  <td
                    v-if="hasTime"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    <span
                      :style="
                        !!item.delayed_time
                          ? 'text-decoration:line-through'
                          : ''
                      "
                    >
                      {{ item.time }}
                    </span>
                    <br /><span v-if="item.delayed_time" style="">{{
                      item.delayed_time
                    }}</span>
                  </td>

                  <td
                    v-if="hasPublishedDate"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    {{ item.published_at }}
                  </td>

                  <td
                    v-if="hasDate"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    <span
                      :style="
                        !!item.delayed_date
                          ? 'text-decoration:line-through'
                          : ''
                      "
                    >
                      {{ item.date }}
                    </span>
                    <br /><span v-if="item.delayed_date" style="">{{
                      item.delayed_date
                    }}</span>
                  </td>

                  <td
                    v-if="hasDateFrom"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    {{ item.start_date }}
                  </td>

                  <td
                    v-if="hasDateTo"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    {{ item.end_date }}
                  </td>

                  <td
                    v-if="hasPaid"
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    <span
                      v-if="item.paid == 1"
                      class="badge badge-soft-info font-size-13"
                    >
                      {{ item.payment_method + " - " + $t("view.paid") }}
                      <i class="fa fa-check-circle font-size-16"></i>
                    </span>
                    <span
                      v-else-if="item.paid == 2"
                      class="badge badge-soft-danger font-size-13"
                    >
                      {{ item.payment_method + " - " + $t("view.notPaid") }}
                      <i class="fa fa-window-close font-size-16"></i>
                    </span>
                    <span v-else> - </span>

                    <p
                      v-if="item.invoice_key"
                      class="badge badge-soft-success font-size-13 mt-1"
                    >
                      {{ item.invoice_key }}
                    </p>

                    <p
                      v-if="
                        item.approved_by_user == 2 ||
                        (item.status == 2 && item.paid)
                      "
                    >
                      <span class="badge badge-soft-success font-size-13 mt-1">
                        Refunded
                      </span>
                    </p>
                  </td>

                  <td
                    v-if="hasStatus"
                    class="text-center"
                    style="vertical-align: middle"
                  >
                    <label
                      v-if="item.status == 1"
                      class="badge rounded-pill badge-md badge-soft-success"
                      :class="
                        $i18n.locale == 'ar'
                          ? 'hacen_algeria font-size-14'
                          : 'font-size-13 ml-1'
                      "
                      style="padding: 10px; margin-bottom: 0"
                      v-html="
                        hasApproved ? $t('app.approved') : $t('app.active')
                      "
                    >
                    </label>
                    <label
                      v-if="item.status == 0"
                      class="badge rounded-pill badge-md badge-soft-primary"
                      :class="
                        $i18n.locale == 'ar'
                          ? 'hacen_algeria font-size-14'
                          : 'font-size-13'
                      "
                      style="padding: 10px; margin-bottom: 0"
                      v-html="hasApproved ? $t('app.new') : $t('app.inactive')"
                    >
                    </label>
                    <label
                      v-if="item.status == 2"
                      class="badge rounded-pill badge-md badge-soft-danger"
                      :class="
                        $i18n.locale == 'ar'
                          ? 'hacen_algeria font-size-14'
                          : 'font-size-13'
                      "
                      style="padding: 10px; margin-bottom: 0"
                      v-html="
                        hasApproved ? $t('app.unapproved') : $t('app.inactive')
                      "
                    >
                      {{ item.reject_reason }}
                    </label>
                    <label
                      v-if="item.status == 3"
                      class="badge rounded-pill badge-md badge-soft-secondary"
                      :class="
                        $i18n.locale == 'ar'
                          ? 'hacen_algeria font-size-14'
                          : 'font-size-13'
                      "
                      style="padding: 10px; margin-bottom: 0"
                      v-html="
                        hasApproved
                          ? $t('app.has_delayed')
                          : $t('app.has_delayed')
                      "
                    >
                    </label>
                  </td>

                  <td v-if="hasStatus2" class="text-center">
                    <label
                      v-if="item.status"
                      class="badge rounded-pill badge-md badge-soft-danger"
                      :class="
                        $i18n.locale == 'ar'
                          ? 'hacen_algeria font-size-14'
                          : 'font-size-13'
                      "
                      style="padding: 10px"
                    >
                      {{ $t("app.seen") }}
                    </label>

                    <label
                      v-if="!item.status"
                      class="badge rounded-pill badge-md badge-soft-success"
                      :class="
                        $i18n.locale == 'ar'
                          ? 'hacen_algeria font-size-14'
                          : 'font-size-13'
                      "
                      style="padding: 10px"
                    >
                      {{ $t("app.unseen") }}
                    </label>
                  </td>

                  <td
                    v-if="
                      (hasProgress &&
                        currentReportType == 'offlineCoursesAttendance') ||
                      currentReportType == 'educationalProgram'
                    "
                    class="text-center"
                    :class="
                      $i18n.locale == 'ar' ? 'hacen_algeria font-size-14' : ''
                    "
                    style="vertical-align: middle"
                  >
                    <span> % {{ item.progress }} </span>
                    <br />
                    <!-- <span v-if="item.progress" style="">{{
                      item.progress
                    }}</span> -->
                  </td>

                  <td v-if="hasActions" class="align-middle">
                    <div class="col-12 text-center">
                      <button
                        v-if="permissions.edit && !hasNoEdit"
                        type="button"
                        @click="editItem(item.encrypt_id)"
                        class="btn btn-success btn-rounded btn-sm ml-1"
                        style="
                          width: 32px;
                          height: 32px;
                          padding: 0;
                          margin-left: 5px;
                        "
                        :title="$t('app.edit')"
                      >
                        <i class="mdi mdi-pencil font-size-16"></i>
                      </button>
                      <button
                        v-if="hasShowEntry"
                        type="button"
                        @click="editItem(item.encrypt_id)"
                        class="btn btn-success btn-rounded btn-sm ml-1"
                        style="
                          width: 32px;
                          height: 32px;
                          padding: 0;
                          margin-left: 5px;
                        "
                        :title="$t('app.show')"
                      >
                        <i class="mdi mdi-eye font-size-16"></i>
                      </button>
                      <button
                        v-if="
                          permissions.edit &&
                          !item.loading &&
                          hasAccept &&
                          item.status != 1 && // || item.status == 1
                          item.is_over == 0
                        "
                        type="button"
                        @click="
                          item.loading = true;
                          editStatusItem(item.encrypt_id, 1);
                        "
                        class="btn btn-success font-size-13"
                        :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'ml-1'"
                        style="height: 28px; padding: 5px; margin-left: 3px"
                      >
                        {{ $t("app.just_approve") }}
                        <i class="fa fa-check-circle font-size-16"></i>
                      </button>
                      <button
                        v-if="
                          permissions.edit &&
                          !item.loading &&
                          hasDecline &&
                          item.status != 2 &&
                          item.contacted != 2 && // || item.status == 1
                          item.is_over == 0
                        "
                        type="button"
                        @click="
                          item.loading = true;
                          showRejectModal(item.encrypt_id, 2);
                        "
                        class="btn btn-danger font-size-13"
                        :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                        style="height: 28px; padding: 5px"
                      >
                        {{ $t("app.just_decline") }}
                        <i class="fa fa-times-circle font-size-16"></i>
                      </button>

                      <button
                        v-if="
                          permissions.edit &&
                          !item.loading &&
                          hasDelayed &&
                          item.contacted != 2 && // || item.status == 1
                          item.is_over == 0
                        "
                        type="button"
                        @click="
                          item.loading = true;
                          showDelayedModal(item.encrypt_id, 3);
                        "
                        class="btn btn-primary font-size-13 ml-1"
                        :class="$i18n.locale == 'ar' ? 'hacen_algeria' : 'ml-1'"
                        style="height: 28px; padding: 5px; margin-left: 3px"
                      >
                        {{ $t("app.delayed") }}
                        <i class="fa fa-clock font-size-16"></i>
                      </button>

                      <router-link
                        v-if="hasShow"
                        type="button"
                        :to="{
                          name: 'show-' + $props.refs,
                          params: { id: item.encrypt_id },
                        }"
                        class="btn btn-primary btn-rounded btn-sm ml-1"
                        style="
                          width: 32px;
                          height: 32px;
                          padding: 0;
                          margin-left: 5px;
                        "
                        title="Show"
                      >
                        <i
                          class="bx bx-show font-size-17"
                          style="padding: 6px"
                        ></i>
                      </router-link>
                      <button
                        v-if="hasReport && permissions.edit"
                        type="button"
                        @click="analyticsItem(item.encrypt_id)"
                        class="btn btn-primary btn-rounded btn-sm ml-1"
                        style="
                          width: 32px;
                          height: 32px;
                          padding: 0;
                          margin-left: 5px;
                        "
                        title="Analytics"
                      >
                        <i
                          class="bx bx-line-chart font-size-17"
                          style="padding: 6px"
                        ></i>
                      </button>
                      <button
                        type="button"
                        v-if="hasExportPDF && !item.loading && permissions.edit"
                        @click="
                          item.loading = true;
                          exportPDFItem(item.encrypt_id);
                        "
                        class="btn btn-primary btn-rounded btn-sm ml-1"
                        style="
                          width: 32px;
                          height: 32px;
                          padding: 0;
                          margin-left: 5px;
                        "
                        title="Export PDF"
                      >
                        <i
                          class="bx bx-file font-size-16"
                          style="padding: 6px"
                        ></i>
                      </button>
                      <button
                        type="button"
                        v-if="hasCoupons && !item.loading"
                        @click="
                          item.loading = true;
                          couponItem(item.encrypt_id);
                        "
                        class="btn btn-primary btn-rounded btn-sm ml-1"
                        style="
                          width: 32px;
                          height: 32px;
                          padding: 0;
                          margin-left: 5px;
                        "
                        title="Coupons"
                      >
                        <i
                          class="bx bx-money font-size-16"
                          style="padding: 6px"
                        ></i>
                      </button>
                      <button
                        type="button"
                        v-if="hasCourses && permissions.edit"
                        @click="showOnlineCourses(item.encrypt_id)"
                        class="btn btn-primary btn-rounded btn-sm ml-1"
                        style="
                          width: 32px;
                          height: 32px;
                          padding: 0;
                          margin-left: 5px;
                        "
                        :title="$t('nav.onlineCourses')"
                      >
                        <i
                          class="bx bx-video-recording font-size-16"
                          style="padding: 6px"
                        ></i>
                      </button>
                      <button
                        type="button"
                        v-if="hasCourses && permissions.edit"
                        @click="showOfflineCourses(item.encrypt_id)"
                        class="btn btn-danger btn-rounded btn-sm ml-1"
                        style="
                          width: 32px;
                          height: 32px;
                          padding: 0;
                          margin-left: 5px;
                        "
                        :title="$t('nav.offlineCourses')"
                      >
                        <i
                          class="bx bx-video-off font-size-16"
                          style="padding: 6px"
                        ></i>
                      </button>
                      <button
                        type="button"
                        v-if="hasWallet"
                        @click="showWallets(item.encrypt_id)"
                        class="btn btn-warning btn-rounded btn-sm ml-1"
                        style="
                          width: 32px;
                          height: 32px;
                          padding: 0;
                          margin-left: 5px;
                        "
                        :title="$t('view.user_wallet')"
                      >
                        <i
                          class="bx bx-money font-size-16"
                          style="padding: 6px"
                        ></i>
                      </button>
                      <!-- && item.progress && item.progress == 100 -->
                      <button
                        type="button"
                        v-if="
                          hasProgress && item.progress && item.progress == 100
                        "
                        @click="addCertificate(item.model_type, item.id)"
                        class="btn btn-primary btn-rounded btn-sm ml-1"
                        style="
                          width: 32px;
                          height: 32px;
                          padding: 0;
                          margin-left: 5px;
                        "
                        :title="$t('view.user_certificate')"
                      >
                        <i
                          class="mdi mdi-plus-circle font-size-16"
                          style="padding: 6px"
                        ></i>
                      </button>
                      <button
                        type="button"
                        v-if="
                          !item.loading &&
                          permissions.delete &&
                          !hasNoDelete &&
                          hasAccept &&
                          item.status != 0
                        "
                        @click="
                          item.loading = true;
                          deleteItem(item.encrypt_id);
                        "
                        class="btn btn-danger btn-rounded btn-sm ml-1"
                        style="width: 32px; height: 32px; padding: 0"
                        :title="$t('role.delete')"
                      >
                        <i class="mdi mdi-delete font-size-17"></i>
                      </button>
                      <a
                        type="button"
                        :href="
                          'https://wa.me/' +
                          (item.user
                            ? item.user.country_code + item.user.mobile
                            : item.country_code + item.mobile) +
                          '?text='
                        "
                        v-if="!item.loading && hasCallWhatsapp"
                        class="btn btn-success btn-rounded btn-sm ml-1"
                        style="width: 32px; height: 32px; padding: 0"
                        target="_blank"
                        :title="$t('app.talking_on_whatsapp')"
                      >
                        <i class="mdi mdi-whatsapp font-size-19"></i>
                      </a>
                      <!-- <button
                        type="button"
                        v-if="!item.loading && hasSuspend && item.suspend == 0"
                        @click="
                          item.loading = true;
                          suspendItem(item.encrypt_id);
                        "
                        class="btn btn-warning btn-rounded btn-sm ml-1"
                        style="width: 32px; height: 32px; padding: 0"
                        :title="$t('role.block')"
                      >
                        <i class="mdi mdi-do-not-disturb font-size-19"></i>
                      </button> -->
                      <button
                        type="button"
                        v-if="!item.loading && hasSuspend && item.suspend == 1"
                        @click="
                          item.loading = true;
                          unSuspendItem(item.encrypt_id);
                        "
                        class="btn btn-secondary btn-rounded btn-sm ml-1"
                        style="width: 32px; height: 32px; padding: 0"
                        :title="$t('role.unblock')"
                      >
                        <i class="mdi mdi-do-not-disturb-off font-size-19"></i>
                      </button>
                      <button
                        type="button"
                        v-if="
                          !item.loading && hasDeleteButton && permissions.delete
                        "
                        @click="
                          item.loading = true;
                          deleteItem(item.encrypt_id);
                        "
                        class="btn btn-danger btn-rounded btn-sm ml-1"
                        style="width: 32px; height: 32px; padding: 0"
                        :title="$t('role.delete')"
                      >
                        <i class="mdi mdi-delete font-size-17"></i>
                      </button>
                      <span
                        v-if="
                          !item.loading &&
                          item.status == 3 &&
                          item.approved_by_user == 0
                        "
                      >
                        <span
                          class="badge rounded-pill badge-md badge-soft-primary font-size-13"
                          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                          style="padding: 10px"
                        >
                          {{ $t("app.waiting_for_customer_approve") }}
                        </span>
                      </span>
                      <span v-if="!item.loading && item.status == 2">
                        <span
                          class="badge rounded-pill badge-md badge-soft-danger font-size-13"
                          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                          style="padding: 10px"
                        >
                        </span>
                      </span>
                      <span
                        v-if="
                          !item.loading &&
                          item.status == 3 &&
                          item.approved_by_user == 1
                        "
                      >
                        <span
                          class="badge rounded-pill badge-md badge-soft-success font-size-13"
                          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                          style="padding: 10px"
                        >
                          {{ $t("app.customer_has_approve") }}
                        </span>
                      </span>
                      <span
                        v-if="
                          !item.loading &&
                          item.status == 3 &&
                          item.approved_by_user == 2
                        "
                      >
                        <span
                          class="badge rounded-pill badge-md badge-soft-danger font-size-13"
                          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                          style="padding: 10px"
                        >
                          {{ $t("app.customer_has_decline") }}
                        </span>
                      </span>
                      <p v-if="item.status == 1" class="mt-1">
                        <span
                          class="badge rounded-pill badge-md badge-soft-danger font-size-13"
                          v-if="item.contacted == 0"
                          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                          style="padding: 10px"
                        >
                          {{ $t("app.lecturer_not_called") }}
                        </span>
                        <span
                          class="badge rounded-pill badge-md badge-soft-warning font-size-13"
                          v-if="item.contacted == 1"
                          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                          style="padding: 10px"
                        >
                          {{ $t("app.lecturer_has_called") }}
                          <br />
                          {{ $t("app.user_not_answer_yet") }}
                        </span>
                        <span
                          class="badge rounded-pill badge-md badge-soft-success font-size-13"
                          v-if="item.contacted == 2"
                          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                          style="padding: 10px"
                        >
                          {{ $t("app.lecturer_has_called") }}
                          <br />
                          {{ $t("app.user_has_answer") }}
                        </span>
                      </p>
                      <p v-if="!permissions.edit && !permissions.delete">
                        <span
                          class="badge rounded-pill badge-md badge-soft-danger font-size-13 mt-1"
                          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                          style="padding: 10px"
                        >
                          <i class="fas fa-exclamation-triangle"></i>
                          {{ $t("view.noPermissionGranted") }}
                        </span>
                      </p>
                      <span v-if="item.loading">
                        <span
                          class="spinner-grow spinner-grow-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        >
                        </span>
                      </span>
                      <span v-if="item.is_over">
                        <span
                          class="badge rounded-pill badge-md badge-soft-danger font-size-13 mt-1"
                          :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
                          style="padding: 10px"
                        >
                          {{ $t("view.date_is_old") }}
                        </span>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p></p>
          <Pagination
            v-if="!dataLoading"
            :items="items"
            :pageCount="pageCount"
            v-on:pageChange="handelPageChange"
          >
          </Pagination>
        </div>
      </div>
    </div>

    <!-- Reject Moble -->
    <div
      v-if="rejectModal"
      class="modal show"
      style="display: block; background: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog" role="document" style="top: 25%; left: -5%">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
            >
              {{ $t("app.please_specify_reject_reason") }}
            </h5>
            <button type="button" class="close" @click="hideRejectModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <textarea
              class="form-control"
              rows="5"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
              v-model="reject_reason"
            >
            </textarea>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
              @click="submitModal()"
            >
              {{ $t("app.save") }}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
              @click="hideRejectModal()"
            >
              {{ $t("app.cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Reject Model -->

    <!-- Delayed Moble -->
    <div
      v-if="delayedModal"
      class="modal show"
      style="display: block; background: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog" role="document" style="top: 25%; left: -5%">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              class="modal-title"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
            >
              {{ $t("app.please_specify_delayed_dateTime") }}
            </h5>
            <button type="button" class="close" @click="hideDelayedModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-12 row">
              <div class="col-6">
                <div class="form-group">
                  <label :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''">
                    {{ $t("app.date") }}
                  </label>
                </div>
                <div class="form-group">
                  <input
                    type="date"
                    class="form-control"
                    v-model="delayed_date"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''">
                    {{ $t("app.time") }}
                  </label>
                </div>
                <div class="form-group">
                  <input
                    type="time"
                    class="form-control"
                    v-model="delayed_time"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
              @click="submitModal()"
            >
              {{ $t("app.save") }}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              :class="$i18n.locale == 'ar' ? 'hacen_algeria' : ''"
              @click="hideDelayedModal()"
            >
              {{ $t("app.cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End Delayed Model -->
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "Table",
  props: [
    "refs",
    "hasReportDropDown",
    "colspan",
    "watchCountryId",
    "locale",

    "hasMultiSelect",
    "hasBulkAction",
    "hasExport",
    "hasExportPDF",
    "hasShowEntries",
    "hasSearch",
    "hasRoleFilter",
    "hasCategoryFilter",

    "hasSearchFilter",
    "hasTimeFilter",
    "hasPeriodFilter",
    "hasDateFilter",
    "hasDateFromFilter",
    "hasDateToFilter",
    "hasTimeFromFilter",
    "hasTimeToFilter",
    "hasPaidFilter",
    "hasStatusApproved",
    "hasStatusFilter",
    "hasLecturerFilter",

    "roleWhereNotIn",

    "hasHashId",
    "lblSearch",

    "role_name",

    "hasCallWhatsapp",

    "hasUser",
    "isAdmin",
    "isAdmin2",

    "hasImage",
    "image",
    "image_style",

    "hasModule",
    "module",

    "hasCoupons",
    "coupons",

    "hasSubject",
    "subject",

    "hasDeleteButton",

    "hasName",
    "name",

    "hasPosition",
    "position",

    "hasTitle",
    "title",

    "hasDetail",
    "detail",

    "hasCategory",
    "category",

    "hasPrice",
    "price",

    "hasPercentage",
    "percentage",

    "hasAttendance",
    "attendance",

    "hasQty",
    "qty",

    "hasQuestion",
    "hasBalance",
    "hasEmail",
    "email",

    "hasCountryCode",
    "countryCode",

    "hasMobile",
    "mobile",

    "hasTotal",
    "totalPrice",

    "hasRelation",
    "relation",

    "hasRole",
    "roleLabel",

    "hasAuthor",
    "hasShow",
    "hasPaid",
    "hasPublishedDate",
    "hasDate",
    "hasDateFrom",
    "hasDateTo",
    "hasStatus",
    "hasStatus2",
    "hasProgress",
    "hasActions",
    "hasSeen",
    "hasApproved",
    "hasTime",
    "hasTimeFrom",
    "hasTimeTo",
    "hasPeriod",
    "hasNoEdit",
    "hasShowEntry",
    "hasAccept",
    "hasDecline",
    "hasDelayed",
    "hasNoDelete",
    "hasWallet",

    "hasReport",
    "hasCourses",

    "actionable",
    "sortable",
    "hasSuspend",
  ],
  components: {
    BulkAction: () => import("@/components/BulkAction.vue"),
    ShowEntries: () => import("@/components/ShowEntries.vue"),
    Pagination: () => import("@/components/Pagination.vue"),
  },
  data() {
    return {
      auth: {
        role: "",
        access_token: "",
      },

      fields: this.$props.fields,

      selected: [],
      selectAll: false,

      parent_id: "",
      slug: "",

      permissions: {
        add: false,
        edit: false,
        delete: false,
      },

      search: "",
      status: "",
      date: "",
      paid: "",
      end_date: "",
      date_from: "",
      date_to: "",
      time: "",
      time_from: "",
      time_to: "",
      period: "",
      filter_by: "",
      filter: "",
      order: "",
      order_by: "",
      role: this.$props.role_name ?? "",
      reject_reason: "",
      rejectModal: false,
      delayedModal: false,
      delayed_date: "",
      delayed_time: "",
      saved_id: "",
      saved_status: "",

      currentReportType: "emergencyConsultations",
      reportTypes: [
        "emergencyConsultations",
        "appointmentConsultations",
        "chatConsultations",
        "videoConsultations",
        "meetingConventions",
        "educationalProgram",
        "voiceConsultations",
        "faqs",
        "books",
        "bookSales",
        "onlineCourses",
        "onlineCoursesAttendance",
        "offlineCourses",
        "offlineCoursesAttendance",
        "combination_reports",
      ],

      dataLoading: true,
      bulkLoading: true,
      exportLoading: true,
      orderLoading: false,
      authorLoading: false,

      roleLoading: true,
      role_id: "",
      roles: [],

      lecturerLoading: true,
      lecturer_id: "",
      lecturers: [],

      categoryLoading: false,
      category_id: "",
      categories: [],

      items: [],
      total: 0,
      page: 1,
      perPage: 10,
      pageCount: 10,
      country_id: this.$props.watchCountryId,
    };
  },
  watch: {
    watchCountryId: function (new_val) {
      this.country_id = new_val;
      this.fetchData(true);
    },
    locale: function (newVal) {
      console.log(newVal);
      this.fetchData(true);
    },
    $route() {
      if (this.$route.params.type) {
        this.currentReportType = this.$route.params.type;
        this.fetchData();
      }
    },
  },

  created() {
    // accessToken
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }
    if (this.$route.query.parent_id) {
      this.parent_id = this.$route.query.parent_id;
    }

    if (this.$route.query.slug) {
      this.slug = this.$route.query.slug;
    }

    if (this.$route.params.type) {
      this.currentReportType = this.$route.params.type;
    }

    // fetchData
    this.fetchData();

    if (this.$props.hasRoleFilter) {
      this.fetchRoles();
    }

    if (this.$props.hasCategoryFilter && this.lecturer_id) {
      this.fetchCategories();
    }

    if (this.$props.hasLecturerFilter) {
      this.fetchLecturers();
    }
  },

  methods: {
    fetchData(loading = false) {
      if (loading) {
        this.dataLoading = true;
      }
      (this.plural = ""), (this.selectAll = false);
      this.selected = [];

      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
        locale: this.$i18n.locale,
      };

      const options = {
        url: window.baseURL + "/" + this.$props.refs,
        method: "GET",
        data: {},
        params: {
          page: this.page,
          paginate: this.perPage,
          parent_id: this.parent_id,
          category_id: this.category_id,
          country_id: this.country_id,
          status: this.status,
          date: this.date,
          end_date: this.end_date,
          period: this.period,
          paid: this.paid,
          date_from: this.date_from,
          date_to: this.date_to,
          time: this.time,
          time_from: this.time_from,
          time_to: this.time_to,
          filter_by: this.filter_by,
          filter: this.filter,
          search: this.search,
          order: this.order,
          order_by: this.order_by,
          role: this.role,
          report: this.currentReportType,
          lecturer_id: this.lecturer_id,
          //role_id: this.role_id,
          // role: this.auth.role
        },
      };
      this.axios(options)
        .then((res) => {
          this.dataLoading = false;
          this.bulkLoading = false;
          this.exportLoading = false;
          this.orderLoading = false;
          this.categoryLoading = false;
          this.authorLoading = false;

          this.items = res.data.data.rows;
          this.total = res.data.data.paginate.total;

          this.permissions.add = res.data.data.permissions.add;
          this.permissions.viewRow = res.data.data.permissions.viewRow;
          this.$emit("hasAddNewPermission", this.permissions.add);

          this.permissions.edit = res.data.data.permissions.edit;
          this.permissions.delete = res.data.data.permissions.delete;
          // console.log(this.$route.fullPath);
          if (res.data.data.paginate.total) {
            this.pageCount = Math.ceil(
              res.data.data.paginate.total / this.perPage
            );
          }
        })
        .catch((err) => {
          //403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "ti-alert",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    fetchLecturers() {
      this.lecturerLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/lecturers",
        method: "GET",
        data: {},
        params: {
          status: "active",
          paginate: 100,
        },
      };
      this.axios(options)
        .then((res) => {
          this.lecturerLoading = false;
          this.lecturers = res.data.data.rows;

          this.fetchCategories();
        })
        .catch((err) => {
          this.lecturerLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },

    fetchRoles() {
      this.roleLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/roles",
        method: "GET",
        data: {},
        params: {
          status: "active",
          whereNotIn: this.$props.roleWhereNotIn,
          paginate: 100,
        },
      };
      this.axios(options)
        .then((res) => {
          this.roleLoading = false;
          this.roles = res.data.data.rows;
        })
        .catch((err) => {
          this.roleLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },

    fetchCat() {
      this.fetchCategories();
      this.fetchData();
    },

    fetchCategories() {
      this.categoryLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/categories",
        method: "GET",
        data: {},
        params: {
          paginate: 1000,
          lecturer_id: this.lecturer_id,
        },
      };
      this.axios(options)
        .then((res) => {
          this.categoryLoading = false;
          this.categories = res.data.data.rows;
        })
        .catch((err) => {
          this.categoryLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },

    // clear Local Storages
    clearLocalStorage() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user_image");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_id");
      localStorage.removeItem("role");
    },

    clearDate() {
      this.date = "";
      this.fetchData();
    },

    clearDateFrom() {
      this.date_from = "";
      this.fetchData();
    },

    clearDateTo() {
      this.date_to = "";
      this.fetchData();
    },

    clearTime() {
      this.time = "";
      this.fetchData();
    },

    clearTimeFrom() {
      this.time_from = "";
      this.fetchData();
    },

    clearTimeTo() {
      this.time_to = "";
      this.fetchData();
    },

    // Handle Change from child components
    handelPageChange(event) {
      this.page = event;
      this.fetchData();
    },

    handelShowChange(event) {
      this.perPage = event;
      this.fetchData(true);
    },

    editItem(id) {
      if (this.$route.query.parent_id) {
        this.$router.push({
          name: "edit-" + this.$props.refs,
          params: { id: id },
          query: { parent_id: this.parent_id, slug: this.slug },
        });
      } else {
        this.$router.push({
          name: "edit-" + this.$props.refs,
          params: { id: id },
        });
      }
    },

    couponItem(id) {
      if (this.$route.query.parent_id) {
        this.$router.push({
          name: "coupon-" + this.$props.refs,
          params: { id: id },
          query: { parent_id: this.parent_id, slug: this.slug },
        });
      } else {
        this.$router.push({
          name: "coupon-" + this.$props.refs,
          params: { id: id },
        });
      }
    },

    showOnlineCourses(id) {
      this.$router.push({
        name: "show-courses-" + this.$props.refs,
        params: { model_type: "onlineCourses", user_id: id },
      });
    },
    showOfflineCourses(id) {
      this.$router.push({
        name: "show-courses-" + this.$props.refs,
        params: { model_type: "offlineCourses", user_id: id },
      });
    },
    showWallets(id) {
      this.$router.push({
        name: "wallet-" + this.$props.refs,
        params: { id },
      });
    },
    addCertificate(type, id) {
      this.$router.push({
        name: "create-" + this.$props.refs,
        params: { type: type, encryptId: id },
        // query: { id: id },
      });
    },

    analyticsItem(id) {
      if (this.$route.query.parent_id) {
        this.$router.push({
          name: "analytics-" + this.$props.refs,
          params: { id: id },
          query: { parent_id: this.parent_id, slug: this.slug },
        });
      } else {
        this.$router.push({
          name: "analytics-" + this.$props.refs,
          params: { id: id },
        });
      }
    },

    deleteItem(id) {
      if (confirm(this.$t("nav.areYouSure"))) {
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/" + this.$props.refs + "/" + id,
          method: "DELETE",
          data: {},
        };
        this.axios(options)
          .then(() => {
            this.fetchData(true);
            iziToast.success({
              icon: "bx bx-wink-smile",
              title: "",
              message: "تم الحذف بنجاح",
            });
          })
          .catch((err) => {
            this.fetchData(true);
            iziToast.error({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          })
          .finally(() => {});
      } else {
        // in case pressed cancel
        this.fetchData(true);
      }
    },

    suspendItem(id) {
      if (confirm(this.$t("nav.areYouSure"))) {
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/" + this.$props.refs + "/suspend/" + id,
          method: "POST",
          data: {},
        };
        this.axios(options)
          .then(() => {
            this.fetchData(true);
            iziToast.success({
              icon: "bx bx-wink-smile",
              title: "",
              message: "تم تعليق الحساب بنجاح",
            });
          })
          .catch((err) => {
            this.fetchData(true);
            iziToast.error({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          })
          .finally(() => {});
      } else {
        // in case pressed cancel
        this.fetchData(true);
      }
    },

    unSuspendItem(id) {
      if (confirm(this.$t("nav.areYouSure"))) {
        this.axios.defaults.headers.common = {
          "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
          Authorization: `Bearer ` + this.auth.access_token,
        };
        const options = {
          url: window.baseURL + "/" + this.$props.refs + "/unsuspend/" + id,
          method: "POST",
          data: {},
        };
        this.axios(options)
          .then(() => {
            this.fetchData(true);
            iziToast.success({
              icon: "bx bx-wink-smile",
              title: "",
              message: "تم إلغاء تعليق الحساب بنجاح",
            });
          })
          .catch((err) => {
            this.fetchData(true);
            iziToast.error({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          })
          .finally(() => {});
      } else {
        // in case pressed cancel
        this.fetchData(true);
      }
    },

    showRejectModal(id, status) {
      this.saved_id = id;
      this.saved_status = status;
      this.rejectModal = true;
    },

    hideRejectModal() {
      this.rejectModal = false;
      this.fetchData(true);
    },

    showDelayedModal(id, status) {
      this.saved_id = id;
      this.saved_status = status;
      this.delayedModal = true;
    },

    hideDelayedModal() {
      this.delayedModal = false;
      this.fetchData(true);
    },

    submitModal() {
      this.editStatusItem(this.saved_id, this.saved_status);
    },

    editStatusItem(id, status) {
      this.rejectModal = false;
      this.delayedModal = false;

      const statusNames = ["approved", "rejected", "delayed"];

      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/lecturers/acceptOrDecline/" + id,
        method: "POST",
        data: {
          category_key: this.$props.refs,
          status: statusNames[status - 1],
          reject_reason: status == 2 ? this.reject_reason : null,
          delayed: status == 3 ? true : false,
          delayed_date: status == 3 ? this.delayed_date : null,
          delayed_time: status == 3 ? this.delayed_time : null,
        },
      };
      this.axios(options)
        .then(() => {
          this.fetchData(true);
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم التعديل بنجاح",
          });
        })
        .catch((err) => {
          this.fetchData(true);
          iziToast.error({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {
          this.saved_id = "";
          this.saved_status = "";
          this.delayed_date = "";
          this.delayed_time = "";
        });
    },

    exportPDFItem(id) {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.$props.refs + "/export/pdf/" + id,
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          window.open(res.data.file, "_blank");
          this.fetchData(true);
        })
        .catch(() => {})
        .finally(() => {});
    },

    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.items) {
          this.selected.push(this.items[i].id);
        }
      }
    },

    activate(id) {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.refs + "/active/" + id,
        method: "POST",
        data: {},
      };
      this.axios(options)
        .then(() => {
          this.fetchData();
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم التنشيط بنجاح",
          });
        })
        .catch((err) => {
          this.fetchData();
          iziToast.error({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    inactivate(id) {
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + "/" + this.refs + "/inactive/" + id,
        method: "POST",
        data: {},
      };
      this.axios(options)
        .then(() => {
          this.fetchData();
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message: "تم إلغاء التنشيط بنجاح",
          });
        })
        .catch((err) => {
          this.fetchData();
          iziToast.error({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        })
        .finally(() => {});
    },

    handelActiveChange() {
      if (this.selected.length == 0) {
        alert("من فضلك أختر العناصر اولاُ");
      } else {
        this.bulkLoading = true;
        this.activate(this.selected.join());
      }
    },

    handelInActiveChange() {
      if (this.selected.length == 0) {
        alert("من فضلك أختر العناصر اولاُ");
      } else {
        this.bulkLoading = true;
        this.inactivate(this.selected.join());
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
select,
option {
  font-family: "hacen_algeria" !important;
}
.is-over-tr {
  /*background: #e8e8e8;*/
  background: #ffeded;
}
.mtop-20 {
  margin-top: -20px;
}
/* input[type="date"]::-webkit-datetime-edit, input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-clear-button {
  color: #fff;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field{
  position: absolute !important;
  /* border-left:1px solid #8c8c8c; */
/* padding: 2px;
  color:#000;
  left: 56px;
}

input[type="date"]::-webkit-datetime-edit-month-field{
  position: absolute !important;
  /* border-left:1px solid #8c8c8c; */
/* padding: 2px;
  color:#000;
  left: 26px;
}


input[type="date"]::-webkit-datetime-edit-day-field{
  position: absolute !important;
  color:#000;
  padding: 2px;
  left: 4px;
} */
</style>